var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 'max-content' : '400px',"color":"#0F1F2F"}},[_c('div',{staticStyle:{"position":"absolute","top":"0","left":"10%"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'width: 45px;' : 'width: 90px;')},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((12),function(i){return _c('div',{key:i,staticClass:"elipse"})}),0)],1),_c('v-main',{key:_vm.key},[_c('v-container',[_c('v-row',{staticStyle:{"z-index":"10"},style:(_vm.$vuetify.breakpoint.smAndDown
            ? 'margin-top: 40px;'
            : 'margin-top: 80px;'),attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{style:(_vm.$vuetify.breakpoint.smAndDown ? 'text-align:center' : ''),attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":"logo","width":_vm.$vuetify.breakpoint.smAndDown ? '140px' : '190px',"height":_vm.$vuetify.breakpoint.smAndDown ? '48px' : '70px'}}),_c('v-row',{staticStyle:{"margin-top":"30px"},attrs:{"no-gutters":"","align":"center","justify":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'start'}},_vm._l((_vm.socialLinks),function(link){return _c('a',{key:link.id,staticClass:"icon",attrs:{"target":"_blank","href":link.value}},[_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"width":"24px","height":"24px","src":link.image.path,"alt":link.image.path}})])}),0)],1),_c('v-col',{style:(_vm.$vuetify.breakpoint.smAndDown
              ? 'text-align:left; margin-top: 67px;'
              : ''),attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('p',{staticClass:"sectionName"},[_vm._v("Телефони диспетчерів")]),_vm._l((_vm.dispatcherPhoneNumbers),function(number){return _c('p',{key:number.id,staticClass:"sectionText"},[_c('a',{staticStyle:{"color":"#b5b5b5"},attrs:{"href":`tel:${number.value}`}},[_vm._v(" "+_vm._s(number.value)+" ")])])})],2),_c('v-col',{style:(_vm.$vuetify.breakpoint.smAndDown
              ? 'text-align:left; margin-top: 32px;'
              : ''),attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('p',{staticClass:"sectionName"},[_vm._v("Контактна інформація")]),_c('p',{staticClass:"sectionText"},[_c('a',{staticStyle:{"color":"#b5b5b5"},attrs:{"href":`mailto:${_vm.email.value}`}},[_vm._v(_vm._s(_vm.email.value))])]),_c('p',{staticClass:"sectionText"},[_vm._v(_vm._s(_vm.address.value))])]),_c('v-col',{style:(_vm.$vuetify.breakpoint.smAndDown
              ? 'text-align:left; margin-top: 32px;'
              : ''),attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('p',{staticClass:"sectionName"},[_vm._v("Додаткова інформація")]),_c('router-link',{attrs:{"to":"/transportation_rules"}},[_c('p',{staticClass:"sectionText",staticStyle:{"color":"#ffffff"}},[_vm._v(" Правила перевезення ")])])],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"copyrightText",staticStyle:{"margin-top":"64px !important","padding-bottom":"80px"},attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('span',[_vm._v(" © A&MExpress 2023 "),(new Date().getFullYear() > 2023)?_c('span',[_vm._v("- "+_vm._s(new Date().getFullYear()))]):_vm._e()])]),_c('v-col',{staticStyle:{"margin":"12px 0px"},attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticStyle:{"border":"1px solid #2c3535","width":"15px","height":"1px","margin":"5px"}})])],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('span',[_vm._v("Developed by")]),_c('img',{staticStyle:{"object-fit":"cover","margin-left":"8px"},attrs:{"src":require("@/assets/img/BMaxLogo.png"),"width":"80px","height":"40px","alt":"bmax logo"}})])],1)],1):_c('v-row',{staticClass:"copyrightText",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('span',[_vm._v(" © A&MExpress 2023 "),(new Date().getFullYear() > 2023)?_c('span',[_vm._v("- "+_vm._s(new Date().getFullYear()))]):_vm._e()]),_c('div',{staticStyle:{"border":"1px solid #2c3535","width":"1px","height":"15px","margin":"5px"}}),_c('span',[_vm._v("Developed by")]),_c('img',{staticStyle:{"object-fit":"cover","margin-left":"8px"},attrs:{"src":require("@/assets/img/BMaxLogo.png"),"width":"80px","height":"40px","alt":"bmax logo"}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }